import * as React from 'react';

import type { DataWarehouse } from 'data-warehouse/Types';
import RestoredArchivesCell from 'data-warehouse/data-warehouse-overview/cells/RestoredArchivesCell';
import Routes from 'routing/Routes';
import type { ColumnRenderers } from 'components/common/EntityDataTable';
import { Timestamp } from 'components/common';
import { Link } from 'components/common/router';

const customColumnRenderers = (): ColumnRenderers<DataWarehouse> => ({
  attributes: {
    last_known_stream_title: {
      renderCell: (last_known_stream_title: string, archive) => (
        <Link to={`${Routes.stream_view(archive.stream_id)}?segment=destinations`}>{last_known_stream_title}</Link>
      ),
      width: 0.4,
    },
    message_count: {
      width: 0.1,
    },
    restored_indices: {
      renderCell: (_restored_indices: string, archive) => <RestoredArchivesCell archive={archive} />,
      width: 0.1,
    },
    timestamp_from: {
      renderCell: (_timestamp_from: string, archive) => archive?.timestamp_from
        && <Timestamp dateTime={archive.timestamp_from} />,
      width: 0.1,
    },
    timestamp_to: {
      renderCell: (_timestamp_to: string, archive) => archive?.timestamp_to
        && <Timestamp dateTime={archive.timestamp_to} />,
      width: 0.1,
    },
  },
});

export default customColumnRenderers;
