import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import styled, { css } from 'styled-components';

import NumberUtils from 'util/NumberUtils';
import type { Backend } from 'archive/types';
import type { BackendTypeValue } from 'archive/logic/BackendTypes';
import BackendTypes from 'archive/logic/BackendTypes';
import { HelpBlock, Input } from 'components/bootstrap';
import { FormikInput, FormSubmit, Section, Select } from 'components/common';
import { defaultCompare as naturalSort } from 'logic/DefaultCompare';
import useHistory from 'routing/useHistory';
import type { DataWarehouseConfig } from 'data-warehouse/configurations/hooks/useDataWarehouseConfig';
import useDataWarehouseConfigMutation from 'data-warehouse/configurations/hooks/useDataWarehouseConfigMutation';
import { DATA_WAREHOUSE_ROUTES } from 'data-warehouse/Constants';
import DataWarehouseBackendWarning from 'data-warehouse/data-warehouse-backend-config/DataWarehouseBackendWarning';

type Props = {
  config: DataWarehouseConfig,
  backends: Array<Backend>,
}

const defaultValues = {
  active_backend: null,
  iceberg_commit_interval: 'PT1H',
  iceberg_target_file_size: 536870912,
  parquet_row_group_size: 134217728,
  parquet_page_size: 8192,
  journal_reader_batch_size: 500,
  optimize_job_enabled: true,
  optimize_job_interval: 'PT1H',
};
const StyledFormSubmit = styled(FormSubmit)(({ theme }) => css`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.spacings.md}
`);
const formatBytes = (value: number) => NumberUtils.formatBytes(value);
const formatDuration = (value: string) => moment.duration(value).humanize();
const isValidDuration = (value: string) => moment.duration(value).asMilliseconds() >= 36000;

const validateDuration = (value: string) => !isValidDuration(value) && 'Invalid duration.';

const formatBackendValidationErrors = (backendErrors: { [fieldName: string]: string[] }) => {
  const backendErrorStrings = mapValues(backendErrors, (errorArray: Array<string>) => `${errorArray.join(' ')}`);

  return { ...backendErrorStrings };
};

const DataWarehouseConfigForm = ({ config, backends }: Props) => {
  const { onUpdateDataWarehouseConfig } = useDataWarehouseConfigMutation();
  const history = useHistory();
  const sortedBackends = backends.map((backend) => ({ value: backend.id, label: `${backend.title} (${BackendTypes.getBackendType(backend.settings.type as BackendTypeValue).label})` }))
    .sort((a, b) => naturalSort(a.label.toLowerCase(), b.label.toLowerCase()));
  const redirectToDataWarehouse = () => history.push(DATA_WAREHOUSE_ROUTES.ARCHIVE.LIST);
  const handleSubmit = (values: DataWarehouseConfig, { setErrors }) => onUpdateDataWarehouseConfig({ config: values })
    .then(() => {
      redirectToDataWarehouse();
    })
    .catch((error) => {
      if (typeof error?.additional?.body?.errors === 'object') {
        setErrors(formatBackendValidationErrors(error.additional.body.errors));
      }
    });

  return (
    <Formik initialValues={config || defaultValues}
            onSubmit={handleSubmit}>
      {({
        values,
        isSubmitting,
        setFieldValue,
        initialValues,
      }) => (
        <Form>
          <Section title="Configuration information">
            <Field name="active_backend">
              {({ field: { name, value }, form: { touched, setFieldTouched } }) => (
                <Input help="Select the active backend."
                       id="active-backend"
                       label="Active Backend">
                  <Select id="active-backend"
                          name="active_backend"
                          placeholder="Select active backend"
                          options={sortedBackends}
                          matchProp="label"
                          required
                          value={value}
                          onChange={(selected) => {
                            setFieldTouched(name, true);
                            setFieldValue(name, selected);
                          }} />
                  <HelpBlock />
                  {(touched[name] && (value !== initialValues[name])) && <DataWarehouseBackendWarning />}
                </Input>
              )}
            </Field>
            <FormikInput type="text"
                         name="iceberg_commit_interval"
                         id="iceberg-commit-interval"
                         label="Iceberg commit interval"
                         help='Interval of Iceberg commits.  (i.e. "P1D" for 1 day, "PT6H" for 6 hours).'
                         addonAfter={formatDuration(values.iceberg_commit_interval)}
                         validate={validateDuration}
                         required />
            <FormikInput type="number"
                         name="iceberg_target_file_size"
                         id="iceberg-target-file-size"
                         label="Iceberg target file size"
                         help="Size of Iceberg target file"
                         addonAfter={formatBytes(values.iceberg_target_file_size)}
                         required />
            <FormikInput type="number"
                         name="parquet_row_group_size"
                         id="parquet-row-group-size"
                         label="Parquet row group size"
                         help="Size of the parquet row group"
                         addonAfter={formatBytes(values.parquet_row_group_size)}
                         required />
            <FormikInput type="number"
                         name="parquet_page_size"
                         id="parquet-page-size"
                         label="Parquet page size"
                         help="Size of the parquet page"
                         addonAfter={formatBytes(values.parquet_page_size)}
                         required />
            <FormikInput type="number"
                         name="journal_reader_batch_size"
                         id="journal-reader-batch-size"
                         label="Journal reader batch size"
                         help="Size of the journal reader batch."
                         required />
            <FormikInput type="checkbox"
                         name="optimize_job_enabled"
                         id="optimize-job-enabled"
                         label="Enable automatic optimization?"
                         help="Whether to automatically optimize the stored data." />
            <FormikInput type="text"
                         name="optimize_job_interval"
                         id="optimize-job-interval"
                         label="Optimize interval"
                         help='Interval of the optimization job.  (i.e. "P1D" for 1 day, "PT6H" for 6 hours).'
                         addonAfter={formatDuration(values.optimize_job_interval)}
                         validate={validateDuration}
                         required />
          </Section>
          <StyledFormSubmit submitButtonText="Update configuration"
                            submitLoadingText="Updating..."
                            isSubmitting={isSubmitting}
                            isAsyncSubmit
                            onCancel={redirectToDataWarehouse} />
        </Form>
      )}
    </Formik>
  );
};

export default DataWarehouseConfigForm;
