import * as React from 'react';
import { useRef } from 'react';
import { styled, css } from 'styled-components';

import { PAGE_PRINT_MARGIN_PX } from 'report/Constants';
import useElementDimensions from 'hooks/useElementDimensions';
import type { Orientation } from 'search/ExportWidgetAction/types';
import type { ReportLayout } from 'report/types';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import usePaperDimensions from 'report/report-creation/common/usePaperDimensions';

const Container = styled.div<{ $orientation: Orientation }>(({ $orientation }) => css`
  width: ${$orientation === 'portrait' ? 60 : 85}%;
  margin: 40px 0;
  height: fit-content;
`);

const FixedDimensions = styled.div<{ $width: number, $height: number }>(({ $width, $height }) => css`
  overflow: hidden;
  position: relative;
  width: ${$width}px;
  height: ${$height + PAGE_PRINT_MARGIN_PX * 2}px;
`);

const Page = styled.div<{ $pageScaleFactor: number, $width: number, $minHeight: number }>(({ $pageScaleFactor, $width, $minHeight }) => css`
  padding: ${PAGE_PRINT_MARGIN_PX}px;
  width: ${$width}px;
  transform: scale(${$pageScaleFactor});
  transform-origin: top left;
  min-height: ${$minHeight}px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: black;
`);

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

const Footer = styled(Header)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Arial;
  gap: 15px;
  background-color: rgba(255, 255, 255, 0.9);
`;

const Logo = styled.div`
  min-width: 130px;
  img {
    max-height: 40px;
    max-width: 130px;
  }
`;

const PageNumbers = styled.div`
  min-width: 130px;
`;

type Props ={
  children: (props: { contentWidth: number }) => React.ReactNode,
  pageSize: ReportLayout['pageSize'] | undefined,
  pageOrientation: Orientation | undefined,
  report: ReportFormValues,
}

const PDFPage = ({ children, pageSize, pageOrientation, report }: Props) => {
  const containerRef = useRef(null);
  const pageRef = useRef(null);
  const { width: containerWidth } = useElementDimensions(containerRef);
  const paperSize = usePaperDimensions(pageSize, pageOrientation);
  const scaledPageDimensions = useElementDimensions(pageRef);
  const pageScaleFactor = containerWidth / paperSize.width;

  return (
    <Container ref={containerRef} $orientation={pageOrientation}>
      <FixedDimensions $width={paperSize.width * pageScaleFactor}
                       $height={scaledPageDimensions.height * pageScaleFactor}>
        <Page $pageScaleFactor={pageScaleFactor}
              $width={paperSize.width}
              $minHeight={paperSize.height}
              ref={pageRef}>
          {/* For the actual PDF the header and footer will be added in the backend */}
          {report.layout?.printHeader && (
            <Header>
              {report.logo && (
              <Logo>
                <img src={report.logo} alt="Report logo" />
              </Logo>
              )}
              {report.layout?.header}
            </Header>
          )}
          {children({ contentWidth: paperSize.width - PAGE_PRINT_MARGIN_PX * 2 })}
          {report.layout?.printFooter && (
            <Footer>
              {report.layout?.printPageNumbers && <PageNumbers>Page <i>current</i> of <i>total</i></PageNumbers>}
              {report.layout?.footer}
            </Footer>
          )}
        </Page>
      </FixedDimensions>
    </Container>
  );
};

export default PDFPage;
