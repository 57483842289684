import __request__ from 'routing/request';
interface SystemProcessBufferDumpResponse {
    readonly processbuffer_dump: {
        readonly [_key: string]: string;
    };
}
interface SystemProcessBufferDumpResponseMap {
    readonly [_key: string]: SystemProcessBufferDumpResponse;
}
interface SystemOverviewResponseMap {
    readonly [_key: string]: SystemOverviewResponse;
}
interface SystemThreadDumpResponse {
    readonly threaddump: string;
}
interface SystemOverviewResponse {
    readonly timezone: string;
    readonly version: string;
    readonly is_leader: boolean;
    readonly lifecycle: string;
    readonly cluster_id: string;
    readonly hostname: string;
    readonly lb_status: string;
    readonly codename: string;
    readonly started_at: string;
    readonly operating_system: string;
    readonly is_processing: boolean;
    readonly facility: string;
    readonly node_id: string;
}
interface SystemJVMResponse {
    readonly total_memory: {
        readonly [_key: string]: number;
    };
    readonly free_memory: {
        readonly [_key: string]: number;
    };
    readonly used_memory: {
        readonly [_key: string]: number;
    };
    readonly pid: string;
    readonly max_memory: {
        readonly [_key: string]: number;
    };
    readonly node_id: string;
    readonly info: string;
}
/**
 * Get system overview of all Graylog nodes
 */
export function get(): Promise<SystemOverviewResponseMap> {
    return __request__('GET', '/cluster', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a process buffer dump of all cluster nodes
 */
export function clusterProcessBufferDump(): Promise<SystemProcessBufferDumpResponseMap> {
    return __request__('GET', '/cluster/processbufferdump', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get JVM information of the given node
 * @param nodeId The id of the node to retrieve JVM information.
 */
export function jvm(nodeId: string): Promise<SystemJVMResponse> {
    return __request__('GET', `/cluster/${nodeId}/jvm`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a process buffer dump of the given node
 * @param nodeId The id of the node to get a process buffer dump.
 */
export function processBufferDump(nodeId: string): Promise<SystemProcessBufferDumpResponse> {
    return __request__('GET', `/cluster/${nodeId}/processbufferdump`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a thread dump of the given node
 * @param nodeId The id of the node to get a thread dump.
 */
export function threadDump(nodeId: string): Promise<SystemThreadDumpResponse> {
    return __request__('GET', `/cluster/${nodeId}/threaddump`, null, {}, {
        'Accept': ['application/json']
    });
}
