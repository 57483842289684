import * as React from 'react';
import { useMemo, useContext, forwardRef, useCallback } from 'react';
import styled from 'styled-components';

import ReportingWidget from 'report/common/ReportingWidget';
import { RenderCompletionObserver } from 'components/visualizations';
import type { Report, WidgetRef, BackendReportWidget } from 'report/types';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import defaultWidgetTitle from 'views/components/defaultTitle';
import Spinner from 'components/common/Spinner';
import useWidgetResult from 'report/hooks/useWidgetResult';
import ReportPreviewContext from 'report/report-creation/ReportPreviewContext';
import ReportRenderPageResults from 'report/report-render-page/ReportRenderPageResults';

const Visualization = styled.div`
  margin-top: 10px;
  margin-bottom: 30px
`;

type Props = {
  hideWidgetDescription: boolean,
  hideWidgetQuery: boolean,
  onWidgetRenderComplete: (widgetId: string) => () => void,
  orientation: Report['layout']['orientation'],
  positions: Report['positions'],
  preview?: boolean,
  widgets: Array<WidgetRef>,
  widgetsRef?: React.MutableRefObject<Array<HTMLElement>>
  width?: number,
};
const WIDGET_ASPECT_RATIO = { portrait: 9 / 23, landscape: 1 / 3 };
const WIDGET_WIDTH = { landscape: 1400, portrait: 1100 };

type WidgetProps = {
  height: number,
  hideDescription: boolean,
  hideQuery: boolean,
  onWidgetRenderComplete: Props['onWidgetRenderComplete'];
  preview?: boolean,
  widget: WidgetRef,
  width: number,
};

export const widgetTitle = (widget: WidgetRef, completeWidget: BackendReportWidget | undefined) => {
  if (widget?.title?.trim()) {
    return widget.title;
  }

  if (completeWidget) {
    return completeWidget?.description ?? defaultWidgetTitle(completeWidget);
  }

  return 'Loading...';
};

const useResultsForPreview = (dashboardId: string, widgetId: string) => {
  const reportPreviewContext = useContext(ReportPreviewContext);
  const widgetIsVisible = !!reportPreviewContext.visibleWidgets[widgetId];

  const { data } = useWidgetResult({
    dashboardId,
    widgetId,
    enabled: widgetIsVisible,
    parameterValues: reportPreviewContext.parameterValues,
    globalOverride: reportPreviewContext.globalOverride,
    staleTime: 1000 * 60 * 60,
  });

  return data;
};

const useResultsForRenderPage = (dashboardId: string, widgetId: string) => {
  const results = useContext(ReportRenderPageResults);

  return useMemo(() => (
    results?.find((result) => result.dashboard_id === dashboardId && result.widget_id === widgetId)?.result
  ), [dashboardId, results, widgetId]);
};

const useResult = (preview: boolean, dashboardId: string, widgetId: string) => {
  if (preview) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useResultsForPreview(dashboardId, widgetId);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useResultsForRenderPage(dashboardId, widgetId);
};

const Widget = forwardRef<HTMLDivElement, WidgetProps>(({
  onWidgetRenderComplete, hideDescription, hideQuery,
  height, width, widget, preview,
}, ref) => {
  const widgetSearchResult = useResult(preview, widget.dashboardId, widget.widgetId);
  const { widgetId } = widget;
  const { widgets: widgetDetails } = useContext(WidgetDetailsContext);
  const completeWidget = widgetDetails[widgetId];
  const _handleRenderComplete = useMemo(() => onWidgetRenderComplete(widgetId), [onWidgetRenderComplete, widgetId]);
  const title = widgetTitle(widget, completeWidget);

  return completeWidget ? (
    <Visualization key={widget.widgetId} ref={ref} data-widget-id={widgetId}>
      <RenderCompletionObserver onRenderComplete={_handleRenderComplete}>
        <ReportingWidget widget={completeWidget}
                         value={widgetSearchResult}
                         showHandle={false}
                         interactive={false}
                         height={height}
                         width={width}
                         hideDescription={hideDescription}
                         hideQuery={hideQuery}
                         title={title}
                         description={widget.description} />
      </RenderCompletionObserver>
    </Visualization>
  ) : <Spinner />;
});

Widget.defaultProps = {
  preview: false,
};

const ReportWidgets = ({
  hideWidgetDescription,
  hideWidgetQuery,
  onWidgetRenderComplete,
  orientation,
  positions,
  widgets,
  widgetsRef,
  preview,
  width: defaultWidth,
}: Props) => {
  const _orientation = orientation ?? 'portrait';
  const width = defaultWidth ?? WIDGET_WIDTH[_orientation];
  const height = width * WIDGET_ASPECT_RATIO[_orientation];

  const assignRef = useCallback((index: number) => (el: HTMLElement) => {
    if (widgetsRef) {
      // eslint-disable-next-line no-param-reassign
      widgetsRef.current[index] = el;
    }
  }, [widgetsRef]);

  const sortedWidgets = useMemo(() => widgets.sort((widgetA, widgetB) => {
    if (positions.length === 0) {
      return 0;
    }

    const positionA = positions.find((p) => p.dashboard_widget_id === widgetA.widgetId);
    const positionB = positions.find((p) => p.dashboard_widget_id === widgetB.widgetId);

    return (positionA && positionB ? positionA.row - positionB.row : 0);
  }), [positions, widgets]);

  return (
    <>
      {sortedWidgets.map((widget, index) => (
        <Widget widget={widget}
                key={widget.widgetId}
                ref={assignRef(index)}
                preview={preview}
                onWidgetRenderComplete={onWidgetRenderComplete}
                height={height}
                width={width}
                hideDescription={hideWidgetDescription}
                hideQuery={hideWidgetQuery} />
      ))}
    </>
  );
};

ReportWidgets.defaultProps = {
  preview: false,
  widgetsRef: undefined,
  width: undefined,
};

export default React.memo(ReportWidgets);
