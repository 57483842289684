import { qualifyUrl } from 'util/URLUtils';
import type { ReportLayout } from 'report/types';

// This timezone will be used when a report has no time zone.
export const REPORT_TZ_FALLBACK = 'UTC';

export const SOURCE_URL = '/plugins/org.graylog.plugins.report';

export const reportingUrl = (path?: string) => {
  const effectivePath = path ? `${SOURCE_URL}/reports/${path}` : `${SOURCE_URL}/reports`;

  return qualifyUrl(effectivePath);
};

export const MAX_LOGO_SIZE = 1024 * 1024; // 1MB

export const DEFAULT_ORIENTATION: ReportLayout['orientation'] = 'portrait';
export const DEFAULT_SIZE: ReportLayout['pageSize'] = 'letter';

// Elements in the report preview need slightly more space compared with the actual PDF.
const WEB_TOLERANCE_FACTOR = 1.027;

// eslint-disable-next-line import/prefer-default-export
export const PAGE_PRINT_MARGIN_IN = 0.3;
export const PAGE_PRINT_MARGIN_PX = 96 * PAGE_PRINT_MARGIN_IN;
export const PAGE_PRINT_MARGIN = `${PAGE_PRINT_MARGIN_IN}in`;
// Print margin for header and footer
export const PAGE_PRINT_MARGIN_LARGE = '0.7in';

export const PAGE_SIZES = [
  {
    label: 'A4',
    value: 'a4',
    portraitDimensions: { width: 816 * WEB_TOLERANCE_FACTOR, height: 1056 * WEB_TOLERANCE_FACTOR },
  },
  {
    label: 'US Letter',
    value: 'letter',
    portraitDimensions: { width: 794 * WEB_TOLERANCE_FACTOR, height: 1123 * WEB_TOLERANCE_FACTOR },
  },
];

export const PAGE_SIZES_MAP = Object.fromEntries(PAGE_SIZES.map((pageSize) => [pageSize.value, pageSize]));
