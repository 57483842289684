import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment/moment';
import * as React from 'react';
import { styled, css, ThemeProvider } from 'styled-components';

import PDFPage from 'report/report-creation/PDFPage';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import type { WidgetRef } from 'report/types';
import useThemes from 'theme/hooks/useThemes';
import { COLOR_SCHEME_LIGHT } from 'theme/constants';
import GlobalOverride from 'views/logic/search/GlobalOverride';
import ReportPreviewContext from 'report/report-creation/ReportPreviewContext';
import ColorSchemeContext from 'theme/ColorSchemeContext';
import RenderedReport from 'report/common/RenderedReport';
import { REPORT_PREVIEW_ID } from 'report/report-creation/ReportPreview';

export const Canvas = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: scroll
`;

const PreviewCanvas = styled(Canvas)(({ theme }) => css`
  background-color: ${theme.colors.gray[90]};
`);

const useObserveWidgetVisibility = (widgets: Array<WidgetRef>) => {
  const widgetsRef = useRef<Array<HTMLElement>>([]);
  const observer = useRef(null);
  const [visibleWidgets, setVisibleWidgets] = useState({});

  const handleObserver = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry) => {
      const widgetId = entry.target.getAttribute('data-widget-id');

      setVisibleWidgets((prevVisibleWidgets) => ({
        ...prevVisibleWidgets,
        [widgetId]: entry.isIntersecting,
      }));
    });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, { rootMargin: '100px' });

    Object.values(widgetsRef.current).forEach((ref) => {
      if (ref) {
        observer.current.observe(ref);
      }
    });

    return () => observer.current.disconnect();
  }, [widgets]);

  // Update visibleWidgets state when widget has been removed
  useEffect(() => {
    const widgetIds = widgets.map(({ widgetId }) => widgetId);
    const removedWidgets = Object.keys(visibleWidgets).filter((widgetId) => !widgetIds.includes(widgetId));

    if (removedWidgets.length > 0) {
      const newVisibleWidgets = Object.fromEntries(Object.entries(visibleWidgets).filter(([widgetId]) => !removedWidgets.includes(widgetId)));

      setVisibleWidgets(newVisibleWidgets);
    }
  }, [widgets, visibleWidgets]);

  return { visibleWidgets, widgetsRef };
};

type Props = {
  report: ReportFormValues,
};

const PDFPreview = ({ report }: Props) => {
  const scrollContainer = useRef(null);
  const { scTheme, colorScheme } = useThemes(COLOR_SCHEME_LIGHT, true);
  const { visibleWidgets, widgetsRef } = useObserveWidgetVisibility(report.widgets);
  const now = useMemo(() => moment(), []);

  const contextValue = useMemo(() => ({
    visibleWidgets,
    parameterValues: report.parameterValues,
    globalOverride: GlobalOverride.builder().now(now).build(),
  }), [now, report.parameterValues, visibleWidgets]);

  const handleTocClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    const tocEntry = event.target as HTMLAnchorElement;

    if (tocEntry.hash) {
      event.preventDefault();
      const widgetHeaderId = tocEntry.hash.substring(1);
      const widgetHeader = document.getElementById(widgetHeaderId);

      if (widgetHeader) {
        const containerTopOffset = scrollContainer.current.getBoundingClientRect().top;
        const widgetTopOffset = widgetHeader.getBoundingClientRect().top;

        scrollContainer.current.scrollTo({
          top: widgetTopOffset - containerTopOffset,
        });
      }
    }
  }, []);

  return (
    <ReportPreviewContext.Provider value={contextValue}>
      <ColorSchemeContext.Provider value={colorScheme}>
        <ThemeProvider theme={scTheme}>
          <PreviewCanvas id={REPORT_PREVIEW_ID} data-testid="report-preview" ref={scrollContainer}>
            <PDFPage report={report}
                     pageSize={report.layout?.pageSize}
                     pageOrientation={report.layout?.orientation}>
              {({ contentWidth }) => (
                <RenderedReport report={report}
                                preview
                                handleTocClick={handleTocClick}
                                width={contentWidth}
                                widgetsRef={widgetsRef} />
              )}
            </PDFPage>
          </PreviewCanvas>
        </ThemeProvider>
      </ColorSchemeContext.Provider>
    </ReportPreviewContext.Provider>
  );
};

export default PDFPreview;
