import * as React from 'react';
import { useState, useCallback } from 'react';

import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import AIReportModal from 'security-app/components/Investigations/AIReport/AIReportModal';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';

const AIReportButton = ({ investigation }: { investigation: InvestigationAPIType }) => {
  const [showAIReportModal, setShowAIReportModal] = useState(false);
  const onGetAIReport = useCallback(() => {
    setShowAIReportModal(true);
  }, []);

  return (
    <>
      <Button bsStyle="primary"
              onClick={onGetAIReport}>
        <Icon name="note_add" type="regular" /> AI Report
      </Button>
      {showAIReportModal && <AIReportModal isConsent={false} onCancel={() => setShowAIReportModal(false)} investigation={investigation} />}
    </>
  );
};

export default AIReportButton;
