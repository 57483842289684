import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';

import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';

import ReportParameters from './ReportParameters';
import WidgetsList from './WidgetsList';
import AddWidget from './AddWidget';

const Header = styled.h3`
  margin-bottom: 15px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-items: center;
  justify-content: space-between;
`;

const Content = () => {
  const { searchIds, parameters } = useContext(WidgetDetailsContext);

  return (
    <>
      {Object.keys(parameters ?? {}).length > 0 ? (
        <>
          <HeaderContainer>
            <Header>Required Parameters</Header>
          </HeaderContainer>
          <ReportParameters parameters={Object.values(parameters).flatMap((p) => p)}
                            parameterSearchIds={searchIds} />

        </>
      ) : null}
      <HeaderContainer>
        <Header>Widgets</Header>
        <AddWidget />
      </HeaderContainer>
      <WidgetsList />
    </>
  );
};

export default Content;
