import React, { useCallback } from 'react';

import DataWarehouseDetailsPageSubactions from '../components/DataWarehouseDetailsPageSubactions';

const useTableElements = () => {
  const entityActions = useCallback((archive) => (
    <DataWarehouseDetailsPageSubactions archive={archive} />
  ), []);

  return {
    entityActions,
  };
};

export default useTableElements;
