import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import useDataWarehouse from 'data-warehouse/hooks/useDataWarehouse';
import { Section, Spinner } from 'components/common';
import FilterRulesList, { StyledSectionComponent } from 'components/streams/StreamDetails/output-filter/FilterRuleList';
import { Alert } from 'components/bootstrap';
import SectionCountLabel from 'components/streams/StreamDetails/SectionCountLabel';
import useStreamOutputFilters from 'components/streams/hooks/useStreamOutputFilters';
import { DEFAULT_PAGINATION } from 'stores/PaginationTypes';
import DataWarehouseSummary from 'data-warehouse/components/DataWarehouseSummary';
import useStreamDataWarehouse from 'data-warehouse/hooks/useStreamDatawarehouse';
import useStreamDataWarehouseStatus from 'data-warehouse/hooks/useStreamDataWarehouseStatus';
import StreamDataWarehouseEnableButton from 'data-warehouse/stream/StreamDataWarehouseEnableButton';
import DataWarehouseRetrieval from 'data-warehouse/components/DataWarehouseRetrieval';

export const Headline = styled.h2(({ theme }) => css`
  margin-top: ${theme.spacings.sm};
  margin-bottom: ${theme.spacings.xs};
`);

const StreamDataWarehouse = () => {
  const { streamId } = useParams<{ streamId: string }>();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { data: config, isFetching } = useStreamDataWarehouseStatus(streamId);
  const { streamDataWarehouseData: { data: archive, isFetching: isFetchingArchive } } = useStreamDataWarehouse(streamId);
  const { onDeleteDataWarehouseRetrievaldOperationMutation } = useDataWarehouse(streamId);
  const { data: paginatedFilters, isSuccess: isLoadingFilterSuccess } = useStreamOutputFilters(streamId, 'data-warehouse', pagination);

  if (isFetching || isFetchingArchive) {
    return <Spinner />;
  }

  const onPaginationChange = (newPage: number, newPerPage: number) => setPagination({
    ...pagination,
    page: newPage,
    perPage: newPerPage,
  });

  const onDeleteRetreiveOperation = (retreivalOperationId: string) => onDeleteDataWarehouseRetrievaldOperationMutation({ streamId, retreivalOperationId });

  const shouldShowWareHouse = (config?.enabled && !!archive) || archive?.message_count > 1 || archive?.restore_history?.length > 0;

  return (
    <Section title="Data Warehouse"
             headerLeftSection={(
               <>
                 <StreamDataWarehouseEnableButton streamId={streamId} config={config} />
                 {shouldShowWareHouse && (<SectionCountLabel>FILTERS {isLoadingFilterSuccess ? paginatedFilters.pagination.total : 0}</SectionCountLabel>)}
               </>
             )}
             collapsible
             defaultClosed
             disableCollapseButton={!shouldShowWareHouse}>
      {shouldShowWareHouse && (
      <>
        <Alert bsStyle="default">
          Storing messages in the Data Warehouse does not count towards license usage (although note that if they are also routed to the Search Cluster, they will still be counted)<br />
          and will be kept until the Data Warehouse Retention Policy is exceeded.<br />
          Note: newly warehoused logs can take up to an hour to be counted in these metrics.
        </Alert>
        <DataWarehouseSummary archive={archive} />
        {paginatedFilters && (
        <FilterRulesList streamId={streamId}
                         paginatedFilters={paginatedFilters}
                         onPaginationChange={onPaginationChange}
                         destinationType="data-warehouse" />
        )}
        <StyledSectionComponent title="Retrieval Operations" collapsible defaultClosed>
          <DataWarehouseRetrieval archive={archive}
                                  onDeleteOperation={onDeleteRetreiveOperation} />
        </StyledSectionComponent>
      </>
      )}
    </Section>
  );
};

export default StreamDataWarehouse;
