import * as React from 'react';
import { useMemo } from 'react';
import type { Moment } from 'moment';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';

import ErrorBoundary from 'export/ErrorBoundary';
import ReportRenderErrorPage from 'report/report-render-page/ReportRenderErrorPage';
import useRoutingQuery from 'routing/useQuery';
import type { TimeRangeQueryParameter } from 'views/logic/TimeRange';
import { timeRangeFromQueryParameter } from 'views/logic/TimeRange';
import RenderedReport from 'report/common/RenderedReport';
import useReport from 'report/report-history-page/useReport';
import useWidgetDetails from 'report/hooks/useWidgetDetails';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import type { ParameterValues, WidgetRef } from 'report/types';
import { fetchAdhocReportValues } from 'report/report-render-page/fetchReportValues';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import type { TimeRange } from 'views/logic/queries/Query';
import type FetchError from 'logic/errors/FetchError';

import ReportRenderPageResults from './ReportRenderPageResults';

type RenderedReportProps = {
  reportId: string,
};

type OptionalNowParameter = {
  now?: string,
};

type ReportRenderPageQuery = ({} | TimeRangeQueryParameter) & OptionalNowParameter;

const extractTimeRangeOverride = (query: ReportRenderPageQuery) => ('rangetype' in query
  ? timeRangeFromQueryParameter(query)
  : undefined);

const referenceTimeFromQueryParameter = (query: OptionalNowParameter) => moment(query.now);

const extractNow = (query: ReportRenderPageQuery) => ('now' in query
  ? referenceTimeFromQueryParameter(query)
  : undefined);

const WidgetDetailsProvider = ({ children, widgets }: React.PropsWithChildren<{ widgets: Array<WidgetRef> }>) => {
  const { data: widgetDetails, isInitialLoading } = useWidgetDetails(widgets);

  return isInitialLoading ? null : (
    <WidgetDetailsContext.Provider value={widgetDetails}>
      {children}
    </WidgetDetailsContext.Provider>
  );
};

const queryKeyForFetch = (id: string, report: ReportFormValues | undefined, now: Moment) => (report && 'id' in report
  ? ['report.values', id, report?.parameterValues, now?.toISOString()] as const
  : ['report.values.new', id, report?.parameterValues, now?.toISOString()] as const);

const fetchValues = (report: ReportFormValues | undefined, parameterValues: ParameterValues | undefined, timerangeOverride: TimeRange, now: Moment) => fetchAdhocReportValues(
  report.widgets.map(({ dashboardId: dashboard_id, widgetId: dashboard_widget_id }) => ({ dashboard_id, dashboard_widget_id })),
  parameterValues,
  timerangeOverride,
  now);

const useReportResults = (report: ReportFormValues | undefined, now: Moment, timerangeOverride: TimeRange) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reportId = useMemo(() => uuidv4(), [report]);
  const queryKey = useMemo(() => queryKeyForFetch(reportId, report, now), [now, report, reportId]);

  return useQuery({
    queryKey,
    queryFn: () => fetchValues(report, report?.parameterValues, timerangeOverride, now),
    enabled: !!report,
  });
};

const RenderReport = ({ reportId }: RenderedReportProps) => {
  const query = useRoutingQuery();
  const timerangeOverride = useMemo(() => extractTimeRangeOverride(query), [query]);
  const now = useMemo(() => extractNow(query), [query]);
  const { data: report, isInitialLoading, error: reportError } = useReport(reportId);
  const { data: reportResults, isLoading, isError, error: resultsError } = useReportResults(report, now, timerangeOverride);

  if (isError || reportError) {
    return <ReportRenderErrorPage error={reportError as Error ?? resultsError as FetchError} />;
  }

  if (isInitialLoading || isLoading) {
    return null;
  }

  return (
    <ReportRenderPageResults.Provider value={reportResults}>
      <WidgetDetailsProvider widgets={report.widgets}>
        <RenderedReport report={report} />
      </WidgetDetailsProvider>
    </ReportRenderPageResults.Provider>
  );
};

const ReportRenderPage = () => {
  const { reportId } = useParams<{ reportId: string }>();

  return (
    <ErrorBoundary FallbackComponent={ReportRenderErrorPage}>
      <RenderReport reportId={reportId} />
    </ErrorBoundary>
  );
};

export default ReportRenderPage;
