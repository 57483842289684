import React, { useMemo } from 'react';

import useTableElements from 'data-warehouse/hooks/useTableElements';
import QueryHelper from 'components/common/QueryHelper';
import {
  DEFAULT_LAYOUT, ADDITIONAL_ATTRIBUTES, COLUMNS_ORDER,
} from 'data-warehouse/data-warehouse-overview/Constants';
import { keyFn, fetchDataWarehouses } from 'data-warehouse/hooks/useDataWarehouses';
import type { DataWarehouse } from 'data-warehouse/Types';
import { PaginatedEntityTable } from 'components/common';

import CustomColumnRenderers from './ColumnRenderers';

const DataWarehouseOverview = () => {
  const { entityActions } = useTableElements();
  const columnRenderers = useMemo(() => CustomColumnRenderers(), []);

  return (
    <PaginatedEntityTable<DataWarehouse> humanName="data warehouses"
                                         columnsOrder={COLUMNS_ORDER}
                                         additionalAttributes={ADDITIONAL_ATTRIBUTES}
                                         queryHelpComponent={<QueryHelper entityName="archive" />}
                                         actionsCellWidth={240}
                                         entityActions={entityActions}
                                         tableLayout={DEFAULT_LAYOUT}
                                         fetchEntities={fetchDataWarehouses}
                                         keyFn={keyFn}
                                         entityAttributesAreCamelCase={false}
                                         columnRenderers={columnRenderers} />
  );
};

export default DataWarehouseOverview;
